<template>
  <b-sidebar
    id="sidebar-detail"
    v-model="isDetailsActive"
    title="Payment Report Detail"
    header-class="text-primary pt-1"
    sidebar-class="sidebar-xl"
    bg-variant="white"
    shadow
    backdrop
    right
    @hidden="close"
  >
    <b-container class="px-4 pt-2">
      <b-row>
        <b-col md="4">
          <custom-input-group
            label="YEAR/MONTH"
            :value="content.year + ' | ' + month"
          />
        </b-col>

        <b-col md="4">
          <custom-input-group label="PERCENT" :value="content.percent" />
        </b-col>

        <b-col md="4">
          <custom-input-group label="AUTOMATIC" :value="content.automatic" />
        </b-col>

        <b-col md="4">
          <custom-input-group
            label="PROGRAM"
            :value="details[0] != null ? details[0].program_name : '-'"
          />
        </b-col>

        <b-col md="4">
          <custom-input-group label="ACCOUNTS" :value="content.accounta" />
        </b-col>

        <b-col md="4">
          <custom-input-group label="MANUAL" :value="content.manual" />
        </b-col>

        <b-col md="4">
          <custom-input-group
            label="ADVISOR"
            :value="details[0] != null ? details[0].advisor_name : '-'"
          />
        </b-col>

        <b-col md="4">
          <custom-input-group
            label="ACCOUNTS WITH PAID"
            :value="content.accountp"
          />
        </b-col>

        <b-col md="4">
          <custom-input-group label="OTHERS" :value="content.other" />
        </b-col>

        <b-col md="4">
          <custom-input-group label="PAYMENTS" :value="content.payment" />
        </b-col>

        <b-col md="4" v-if="content.year < 2024">
          <custom-input-group label="ZERO PAYMENTS" :value="content.zero" />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-4 mt-2 mb-2">
      <b-row class="mb-1">
        <b-col
          md="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted">
            Showing {{ paginate.startPage }} to {{ paginate.toPage }} of
            {{ totalRows }} entries
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="getReportDetails"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <b-col md="6">
          <div
            class="
              d-flex
              align-items-center
              justify-content-end
              align-items-center
            "
          >
            <b-input-group v-if="!advanceSearch" class="mr-1">
              <b-form-input
                v-model="filters.campo1"
                @keyup.enter="getReportDetails"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="getReportDetails">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button
              v-b-tooltip.bottom="'Advanced search'"
              variant="primary"
              @click="changeAdvanceSearch"
            >
              <div
                class="d-flex justify-content-between"
                style="white-space: nowrap"
              >
                <feather-icon icon="FilterIcon" size="15" class="mr-50" />
                {{ !advanceSearch ? "Advanced Search" : "Basic Search" }}
              </div>
            </b-button>
          </div>
        </b-col>

        <b-col md="12" class="mt-2">
          <b-row class="justify-content-end">
            <b-col md="5">
              <div>
                <label>Show</label>
                <v-select
                  v-model="paginate.perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="getReportDetails"
                />
                <label class="mr-2">entries</label>
                <feather-icon
                  class="cursor-pointer"
                  icon="RefreshCcwIcon"
                  size="20"
                  @click="getReportDetails"
                />
              </div>
            </b-col>
            <b-col v-if="!advanceSearch" md="7" />
            <b-col v-if="advanceSearch && filters.typePayment != 1" md="2" />
            <template v-if="advanceSearch">
              <b-col md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.typePayment"
                    placeholder="Payment Type"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: 'Automatic', value: 1 },
                      { label: 'Manual', value: 2 },
                      { label: 'Others', value: 3 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="filters.typePayment == 1" md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.day"
                    placeholder="Day Payment"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: '5', value: 5 },
                      { label: '10', value: 10 },
                      { label: '15', value: 15 },
                      { label: '20', value: 20 },
                      { label: '25', value: 25 },
                      { label: '30', value: 30 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group>
                  <v-select
                    v-model="filters.payment"
                    placeholder="Paid"
                    :options="[
                      { label: 'All', value: 0 },
                      { label: 'YES', value: 1 },
                      { label: 'PENDING', value: 3 },
                      { label: 'NO', value: 2 },
                    ]"
                    :reduce="(el) => el.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  @click="getReportDetails"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-table :fields="table.fields" :items="details" show-empty>
      <template #cell(account)="data">
        <router-link
          target="_blank"
          :to="{
            name: programRedirect(programid),
            params: {
              idClient: data.item.id,
            },
          }"
          v-if="programid == 1 || moduleId == 4"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          target="_blank"
          :to="{
            name: 'debt-solution-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          :class="[textLink]"
          v-else-if="moduleId === 5"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'boost-credit-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 7"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'credit-experts-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 6"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'claim-department-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 10"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'bookeeping-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 14"
          target="_blank"
          :class="[textLink]"
          >{{ data.item.account }}</router-link
        >
        <router-link
          :to="{
            name: 'tax-research-client-dashboard',
            params: {
              idClient: data.item.id,
            },
          }"
          v-else-if="moduleId === 8"
          target="_blank"
          >{{ data.item.account }}</router-link
        >
        <div v-else>{{ data.item.account }}</div>
      </template>

      <template #cell(payment_type)="data">
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb == 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '0' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="
            data.item.type_payment == 'PA' &&
            data.item.status_payment == '1' &&
            data.item.srb != 'ACTIVE'
          "
          :title="'Automatic/' + data.item.srb"
          :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'MA'"
          title="Manual"
          :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
          alt=""
        />
        <img
          v-if="data.item.type_payment == 'OT'"
          title="Others"
          :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
          alt=""
        />
        <span v-if="data.item.day_payment != null">
          ({{ data.item.day_payment }})
        </span>
      </template>

      <template #cell(paid)="data">
         <span
           :style="data.item.ispayment === 0 ? 'color:#FF0000' : (data.item.ispayment === 1 ? 'color:#00CC00' : 'color:orange')"
         >
  {{ data.item.ispayment === 0 ? "NO" : (data.item.ispayment === 1 ? "YES" : "PENDING") }}
</span>
      </template>

      <template #cell(amount)="data">
        <ul style="padding-left: 0px; margin-bottom: 0px">
          <li
            v-for="(payment, index) in JSON.parse(data.item.payments)"
            :key="index"
            style="list-style: none"
            :class="data.item.ispayment == 0 ? 'text-danger' : ''"
          >
            {{payment.amount ? "$ " + (data.item.ispayment == 0 ? "-" : ""):''}} {{payment.amount|currency}} 
            <span v-if="payment.refund != 0" class="text-info" style="font-size:10px">({{payment.refund|currency}})</span>
            <span v-if="payment.void != 0" class="text-primary" style="font-size:10px">({{payment.void|currency}})</span>
          </li>
        </ul>
      </template>

      <template #cell(date)="data">
        <ul style="padding-left: 0px; margin-bottom: 0px">
          <li
            v-for="(payment, index) in JSON.parse(data.item.payments)"
            :key="index"
            style="list-style: none"
          >
            <span v-if="payment.settlement_date"
              >{{ payment.settlement_date | myGlobal }}
            </span>
          </li>
        </ul>
      </template>
      <template v-slot:custom-foot>
        <b-tr class="td-totals" v-for="i in 2" :key="i">
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer"> </b-td>
          <b-td class="backg_footer text-success text-right" v-if="i == 2">
            Total Paid
          </b-td>
          <b-td class="backg_footer text-danger text-right" v-else>
            Total Unpaid
          </b-td>
          <b-td class="backg_footer text-danger" v-if="i == 1">
            <span title="Total Unpaid">$ {{ unpaid | currency }}</span>
          </b-td>
          <b-td class="backg_footer text-success" v-else>
            <span title="Total Paid">$ {{ paid  | currency }} <span v-if="refund && paid != refund" class="text-warning" style="font-size:10px">({{refund|currency}})</span></span>
          </b-td> 
          <b-td class="backg_footer"> </b-td>
        </b-tr>
      </template>
    </b-table>

    <b-container class="px-4 mt-2 mb-2">
      <b-row>
        <b-col md="6">
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @input="getReportDetails"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </b-sidebar>
</template>

<script>
// Components
import vSelect from "vue-select";
import MaReportsService from "@/views/commons/components/reports/reports.service";
import CustomInputGroup from "../../../components/CustomInputGroup.vue";

// Services

export default {
  components: {
    vSelect,
    CustomInputGroup,
  },
  props: {
    content: {
      type: Object,
    },
    programid: {
      type: Number,
    },
  },
  data() {
    return {
      isDetailsActive: false,
      table: {
        fields: [
          { key: "account", label: "ACCOUNT" },
          { key: "client_name", label: "NAME" },
          { key: "mobile", label: "MOBILE" },
          this.programid != 2
            ? { key: "payment_type", label: "PAYMENT TYPE" }
            : null,
          { key: "paid", label: "PAID" },
          { key: "amount", label: "AMOUNT" },
          { key: "date", label: "DATE" },
        ],
      },

      paginate: {
        currentPage: 1,
        perPage: 100,
        startPage: 0,
        toPage: 0,
      },
      perPageOptions: [10, 25, 50, 100],
      totalRows: 10,
      advanceSearch: false,

      filters: {
        campo1: "",
        day: "",
        payment: null,
        typePayment: "",
      },
      sum_ispayment: 0,
      sum_notpayment: 0,

      type: null,
      details: [],
      unpaid: 0,
      paid: 0.0,
      refund: 0.0,
    };
  },
  computed: {
    month() {
      const arrMonth = [
        "",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return arrMonth[this.content.month];
    },
    total_month() {
      return this.content.amount;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    "filters.typePayment": function (newVal) {},
  },
  async created() {
    await this.getReportDetails();
  },
  mounted() {
    this.isDetailsActive = true;
  },
  methods: {
    changeAdvanceSearch() {
      this.advanceSearch = !this.advanceSearch;

      this.filters.campo1 = "";
      this.filters.payment = null;
      this.filters.day = null;
      this.filters.typePayment = null;
    },
    async getReportDetails() {
      try {
        this.paid = 0;
        this.unpaid = 0;
        this.refund = 0;

        this.addPreloader();
        let advisor;
        if (this.moduleId === 4) {
          advisor = null;
        } else {
          advisor = this.content.advisor;
        }

        const response = await MaReportsService.getPaymentReportDetails({
          year: this.content.year,
          month: this.content.month,
          program: this.content.program,
          advisor: advisor,
          type: this.type,
          payment: this.filters.payment,
          campo1: this.filters.campo1,
          day: this.filters.day,
          typepayment: this.filters.typePayment,
          page: this.paginate.currentPage,
          perpage: this.paginate.perPage,
        });

        if (response.status == 200) {
          this.paid = 0;
          this.unpaid = 0;
          this.refund = 0;
          this.details = response.data.data;
          // this.paid = this.details[0].amount_total;
          // this.unpaid = this.details[0].remaining;
          this.totalRows = response.data.total;
          this.paginate.startPage = response.data.from;
          this.paginate.toPage = response.data.to;

          this.details.map((item) => { 
            if (item.payments) {
              if (JSON.parse(item.payments)) {
                
                if (item.ispayment == 1 || item.ispayment == 2 ) { 
                  let paymentsArray = JSON.parse(item.payments); 
                  let totalAmount = paymentsArray.reduce((sum, currentPayment) => sum + parseFloat(currentPayment.amount), 0);   
                  this.paid +=totalAmount;   
                  this.refund +=parseFloat(!(JSON.parse(item.payments)[0].refund != 0 || JSON.parse(item.payments)[0].void != 0)? totalAmount  : (JSON.parse(item.payments)[0].refund??JSON.parse(item.payments)[0].void));  
                } else {
                  this.unpaid += JSON.parse(item.payments)[0].amount;
                }
              }
            }
          });
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    numFloat(numz) {
      if (numz) {
        const caracter = ",";
        let validarz = numz.includes(caracter);
        if (validarz) {
          return parseFloat(numz.replace(",", ""));
        } else {
          if (numz == "-" || numz == "") {
            return 0;
          } else {
            return parseFloat(numz);
          }
        }
      } else {
        return 0;
      }
    },
    programRedirect(program) {
      let routeName = "";
      if (program == 1) {
        routeName = "bussiness-dashboard-clients";
      } else if (program == 4) {
        routeName = "debt-solution-dashboard";
      } else if (program == 2) {
        routeName = "boost-credit-dashboard";
      } else if (program == 3) {
        routeName = "credit-experts-dashboard";
      } else if (program == 8) {
        routeName = "tax-research-client-dashboard";
      } else if (program == 0) {
        routeName = `administration-dashboard`;
      }
      return routeName;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.font-size-09 {
  font-size: 9pt !important;
}

.sidebar-xl {
  width: 90rem !important;
}
.backg_footer {
  background: #000000af;
  color: #fff;
  font-weight: 600;
}
</style>
