<template>
  <div>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th v-for="(item,index) in monthsRefresh" :key="index">
            <span class="d-flex justify-content-center">{{item.month}}</span>
            <div class="d-flex justify-content-center">
              <b-form-checkbox v-model="item.refresh" :disabled="item.disabled"/>
            </div>
          </b-th> 
        </b-tr>
      </b-thead>
      <b-tbody style="font-size: 12px">
        <template v-for="(item, index) in reports">
          <b-tr
            v-if="item.col1 == 'REMAINING'"
            :key="index"
            meta="FFFFC3"
            class="text-warning"
          >
            <b-td class="font-size-09">
              {{ item.col1 }}
            </b-td>
            <b-td
              v-for="(cont, indexCont) in 12"
              :key="indexCont"
              :style="
                index == 0
                  ? 'color:' + (isDarkTheme ? '#BABABA' : 'black')
                  : index == 1
                  ? ' font-weight: bold;'
                  : index == 2
                  ? 'font-weight: bold;'
                  : index == 3
                  ? 'font-weight: bold;'
                  : index == 4
                  ? 'font-weight: bold; '
                  : index == (11 - (isNewRange?0:1))
                  ? 'background:#008FE6; border: 1px solid white; color:white;  '
                  : ''
              "
            >
              <span
                class="d-flex justify-content-center text-warning"
                :style="item.type_row == 11 ? 'font-weight: bolder ' : ''"
              >
                {{ item.type_row == 9 ? "$ " : "" }}
                {{ item[monthValues[indexCont + 1]] }}
              </span>
            </b-td>
          </b-tr>
          <b-tr
            v-if="item.col1 != 'REMAINING'"
            :key="index"
            meta="FFFFC3"
            :style="
              index == 1
                ? 'background:' +
                  (isDarkTheme ? '#2169D280' : '#E8F1FF') +
                  ';color:' +
                  (isDarkTheme ? 'white' : '#2169D2')
                : index == 2
                ? 'background:' +
                  (isDarkTheme ? '#E627D980' : '#FFE7FF') +
                  ';color:' +
                  (isDarkTheme ? '' : '#E627D9')
                : index == 3
                ? 'background :' +
                  (isDarkTheme ? '#E91F3880' : '#FFE7EB') +
                  ';color:' +
                  (isDarkTheme ? '' : '#E91F38')
                : index == 4
                ? 'background:' +
                  (isDarkTheme ? '#CD8C1480' : '#FFF7E8') +
                  ';color:' +
                  (isDarkTheme ? '' : '#CD8C14')
                : index == (11 - (isNewRange?0:1))
                ? 'background:' + (isDarkTheme ? '#393939' : '#C5C5C5')
                : ''
            "
          >
            <b-td
              :style="
                index == 0
                  ? 'color:' + (isDarkTheme ? '#BABABA' : 'black')
                  : index == 1
                  ? ' font-weight: bold;' +
                    'color:' +
                    (isDarkTheme ? '' : '#2169D2')
                  : index == 2
                  ? 'font-weight: bold;' +
                    'color:' +
                    (isDarkTheme ? '' : '#E627D9')
                  : index == 3
                  ? 'font-weight: bold;' +
                    'color:' +
                    (isDarkTheme ? '' : '#E91F38')
                  : index == 4
                  ? 'font-weight: bold;' +
                    'color:' +
                    (isDarkTheme ? '' : '#CD8C14')
                  : index == (8 - (isNewRange?0:1))
                  ? 'color:#008FE6;font-weight: bold;'
                  : index == (9 - (isNewRange?0:1))
                  ? 'color:red;font-weight: bold;'
                  : index == (10 - (isNewRange?0:1))
                  ? 'color:green;font-weight: bold;'
                  : index == (11 - (isNewRange?0:1))
                  ? 'background:#008FE6;font-weight: bold;color:white'
                  : ''
              "
              class="font-size-09"
            >
              {{ item.col1 }}
            </b-td>

            <b-td
              v-for="(cont, indexCont) in 12"
              :key="indexCont"
              :style="
                index == 0
                  ? 'color:' + (isDarkTheme ? '#BABABA' : 'black')
                  : index == 1
                  ? ' font-weight: bold;'
                  : index == 2
                  ? 'font-weight: bold;'
                  : index == 3
                  ? 'font-weight: bold;'
                  : index == 4
                  ? 'font-weight: bold; '
                  : index == (8 - (isNewRange?0:1))
                  ? 'color:#008FE6;font-weight: bold;'
                  : index == (9 - (isNewRange?0:1))
                  ? 'color:red;font-weight: bold;'
                  : index == (10 -(isNewRange?0:1))
                  ? 'color:green;font-weight: bold;'
                  : index == (11 - (isNewRange?0:1))
                  ? 'background:#008FE6; border: 1px solid white; color:white;  '
                  : ''
              "
            >
              <span
                class="d-flex justify-content-center"
                :style="item.type_row == 11 ? 'font-weight: bolder ' : ''"
              >
                {{ [81, 9].some((e) => e == item.type_row) ? "$ " : "" }}
                {{
                  item[monthValues[indexCont + 1]] == "-"
                    ? "0"
                    : item[monthValues[indexCont + 1]]
                }}
              </span>
            </b-td>
          </b-tr>
        </template>
        <b-tr>
          <b-td></b-td>
          <b-td v-for="(item, index) in 12" :key="index">
            <div class="d-flex justify-content-center">
              <!-- no renderiza el boton de detalle si no hay payments registrados en dicho mes -->
              <b-button
                v-if="
                  reports[1][monthValues[index + 1]] != '-' &&
                  reports[1][monthValues[index + 1]] != 0
                "
                variant="outline-primary"
                size="sm"
                class="mt-1"
                @click="viewDetails(index + 1)"
              >
                DETAILS
              </b-button>
              <b-badge v-else class="mt-1" variant="danger">
                NO DETAILS
              </b-badge>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
export default {
  props: {
    tabName: {
      type: String,
      default: null,
    },
    reports: {
      type: Array,
    },
    year: {
      type: Number,
      required:false,
    },
  },
  data() {
    return {
      program: "",
      monthValues: [
        "",
        "value_jan",
        "value_feb",
        "value_mar",
        "value_apr",
        "value_may",
        "value_jun",
        "value_jul",
        "value_aug",
        "value_sep",
        "value_oct",
        "value_nov",
        "value_dec",
      ],
      isShow:true,
      monthsRefresh:[
        {id: 1 , month:"JAN" , refresh:false , disabled:false},
        {id: 2 , month:"FEB" , refresh:false , disabled:false},
        {id: 3 , month:"MAR" , refresh:false , disabled:false},
        {id: 4 , month:"APR" , refresh:false , disabled:false},
        {id: 5 , month:"MAY" , refresh:false , disabled:false},
        {id: 6 , month:"JUN" , refresh:false , disabled:false},
        {id: 7 , month:"JUL" , refresh:false , disabled:false},
        {id: 8 , month:"AUG" , refresh:false , disabled:false},
        {id: 9 , month:"SEP" , refresh:false , disabled:false},
        {id: 10 , month:"OCT" , refresh:false , disabled:false},
        {id: 11 , month:"NOV" , refresh:false , disabled:false},
        {id: 12 , month:"DEC" , refresh:false , disabled:false}
      ] 
    }
  },
  created() {
    if (this.moduleId === 5) {
      this.program = 4
    }
    if (this.moduleId === 12) {
      this.program = 9
    }
 
  },
  mounted(){
    this.selectedMonthRefresh();
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark"
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
    isNewRange(){
      return this.year < 2024;
    },
    getMonthsRefresh(){ 
      return this.monthsRefresh.filter((item)=>item.refresh).map((item)=>item.id);
    },
  },
  methods: { 
    viewDetails(month) { 
      let newarr = {
        month: month,
        percent: this.reports[0][this.monthValues[month]],
        accounta: this.reports[1][this.monthValues[month]],
        payment: this.reports[3][this.monthValues[month]],
        automatic: this.reports[4][this.monthValues[month]],
        manual: this.reports[5][this.monthValues[month]],
        other: this.reports[6][this.monthValues[month]],
        accountp: this.reports[2][this.monthValues[month]],
        zero: this.reports[7][this.monthValues[month]],
        program: this.program,
      }

      if (this.reports.length > 8) {
        newarr.amount = this.reports[8][this.monthValues[month]]
        newarr.remaining = this.reports[9][this.monthValues[month]]
      }
      this.$emit("onViewDetails", newarr)
    },
    selectedMonthRefresh(){
      const currentYear = new Date().getFullYear(); 
      if(this.year == currentYear){
        const currentMonth = new Date().getMonth();
        this.monthsRefresh[currentMonth].refresh = true;  
        this.monthsRefresh.forEach(item => {
          if (item.id > (currentMonth+1) ) {
            item.disabled = true;
          }
        });
      } else {
          this.monthsRefresh.forEach(item => { 
            item.refresh = false; 
            item.disabled = false; 
        });
      }
    } 
  },
  watch:{
    year(){
      this.selectedMonthRefresh();
    }
  }
}
</script>

<style>
.font-size-09 {
  font-size: 9pt !important;
}
</style>
