<template>
  <div> 
    <b-row class="mb-2 mx-1 mt-3">
      <b-col xl="8" lg="7" md="6" sm="12">
        <p>LAST UPDATE {{ lastUpdate | myGlobalDay }}</p>
      </b-col>
      <b-col xl="4" lg="5" md="6" sm="12">
        <div
          v-if="value_progress != 0 && isProgress"
          class="d-flex align-items-center justify-content-end align-items-center"
        >
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <div class="letter-holder">
                <div>Generating...</div>
              </div>
              <span class="text-center">{{
                `${value_progress.toFixed(2)}%`
              }}</span>
            </div>

            <b-progress :max="100">
              <b-progress-bar :value="value_progress" animated></b-progress-bar>
            </b-progress>
          </div>
 
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-end align-items-center"
        >
          <b-col
            md="3"
            v-if="new Date() < dateDeploymentReport"
            class="text-center"
          >
            <span class="text-info">
              Available From {{ dateDeploymentReport | myGlobal }}
            </span>
          </b-col>
          <b-button
            ref="button_refresh"
            variant="success"
            class="btn-icon mr-1 d-flex"
            style="white-space: nowrap"
            :disabled="
              timerActive ||
              !(!isProgress && isCreated) ||
              new Date() < dateDeploymentReport
            "
            @click="generateReport"
          >
            <template>
              <feather-icon icon="RefreshCcwIcon" class="mr-50" />
              Generate
            </template>
            <!-- <template v-else> {{ minutesTime }} : {{ secondsTime }} </template> -->
          </b-button>

          <b-input-group class="mr-1">
            <b-form-select
              v-model="filters.year"
              text-field="label"
              value-field="value"
              :options="filtersData.years"
            />
            <b-input-group-append>
              <b-button
                id="ma-re-btn-search"
                variant="primary"
                class="btn-icon mr-1"
                @click="getReports"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button
            variant="primary"
            class="btn-icon"
            @click="openSidebarFilters"
            v-if="
              this.currentUser.role_id != 3 && this.currentUser.role_id != 8
            "
          >
            <feather-icon icon="FilterIcon" />
          </b-button>
        </div>
      </b-col> 
    </b-row>
    
    <TableReport
      ref="tableReportPayment"
      v-if="!isProgress && isCreated && year"
      :reports="reports"
      :year="year"
      @onViewDetails="openSidebarViewDetails"
      :tab-name="tabName"
    />
    <b-skeleton-table
      v-else
      :rows="10"
      :columns="10"
      :table-props="{ striped: true }"
    ></b-skeleton-table>

    <SidebarDetails
      v-if="showSidebarViewDetails"
      :content="content"
      :programid="[16, 4].includes(moduleId) ? 0 : programid"
      @close="closeSidebarViewDetails"
    />

    <!-- Filters -->
    <Filters
      :is-filters-active.sync="showSidebarFilters"
      :filters="filters"
      :filters-data="filtersData"
      @onChangeProgram="getUserPrograms"
      @onSearch="getReports"
      @onClose="closeSidebarFilters"
      @reset-all-filters="getReports"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

// Components
import vSelect from "vue-select";
import MaReportsService from "@/views/commons/components/reports/reports.service";
import TableReport from "./components/Table.vue";
import SidebarDetails from "./components/SidebarDetails.vue";
import Filters from "./components/FiltersComponent.vue";

// Services

export default {
  props: {
    tabName: {
      type: String,
      default: null,
    },
  },
  components: {
    vSelect,
    TableReport,
    SidebarDetails,
    Filters,
  },
  data() {
    return {
      filters: {
        year: moment().format("YYYY"),
        program: "",
        advisor: "",
      },
      detailsSidebarOn: false,
      filtersData: {
        years: [],
        programs: [],
        advisors: [],
      },
      programid: "",
      details: {
        year: moment().format("YYYY"),
        program: "",
        advisor: "",
      },

      reports: null,
      content: {},
      lastUpdate: null,

      secondsTime: 59,
      minutesTime: 1,
      timerActive: false,

      // Modals
      showSidebarViewDetails: false,
      showSidebarFilters: false,
      isCreated: false,
      value_progress: 0,
      isProgress: false,
      dateDeploymentReport: null,
      year: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isSuperAdmin() {
      return (
        this.isSupervisor ||
        this.isCeo ||
        (this.isTeamLeader && this.moduleId == 6)
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.filters.advisor = this.isSuperAdmin ? null : this.currentUser.user_id;

    if (this.moduleId === 5) {
      this.programid = 4;
      this.filters.program = 4;
      this.details.program = 4;
    } else if (this.moduleId === 7) {
      this.programid = 2;
      this.filters.program = 2;
      this.details.program = 2;
    } else if (this.moduleId === 6) {
      this.programid = 3;
      this.filters.program = 3;
      this.details.program = 3;
    } else if (this.moduleId === 10) {
      this.programid = 6;
      this.filters.program = 6;
      this.details.program = 6;
    } else if (this.moduleId === 14) {
      this.programid = 8;
      this.filters.program = 8;
      this.details.program = 8;
    }
    if (this.moduleId === 3) {
      this.programid = 1;
      this.filters.program = 1;
      this.details.program = 1;
    }
    if (this.moduleId === 8) {
      this.programid = 5;
      this.filters.program = 5;
      this.details.program = 5;
    }
    if (this.moduleId === 4) {
      this.programid = 0;
    }
    if (this.moduleId === 11) {
      this.programid = 7;
      this.filters.program = 7;
      this.details.program = 7;
      this.filters.advisor = null;
    }
    await this.getYears();

    await this.getReports();

    await this.getAllPrograms();

    if (this.filters.advisor == null) {
      await this.getUserPrograms();
    }
    this.isCreated = true;
  },
  async mounted() { 
    await this.destroySocket();
    await this.activeSocketChangeProgress();
    // await this.getDateDeploymentReport();
  },
  methods: {
    openSidebarViewDetails(data) {
      this.details.year = this.filters.year;
      this.details.program = this.filters.program;
      this.details.advisor = this.filters.advisor;
      data.year = this.details.year;
      data.program = this.details.program;
      data.advisor = this.filters.advisor;
      this.content = data;
      this.showSidebarViewDetails = true;
    },
    closeSidebarViewDetails() {
      this.showSidebarViewDetails = false;
    },
    openSidebarFilters() {
      this.showSidebarFilters = true;
    },
    closeSidebarFilters(save) {
      if (save) {
        this.details.year = this.filters.year;
        this.details.program = this.filters.program;
        this.details.advisor = this.filters.advisor;
      }

      this.showSidebarFilters = false;
    },
    async generateReport() { 
      if(this.$refs['tableReportPayment'].getMonthsRefresh.length <=0){
        this.showToast("info", "top-right", "Oops!", "XIcon", "select the months you wish to generate");
        return;
      }
      try {
        this.timerActive = true;
        // await this.getDateDeploymentReport();
        const { data } = await MaReportsService.generateReport({
          year: this.year,
          user_id: this.currentUser.user_id,
          monthsRefresh : this.$refs['tableReportPayment'].getMonthsRefresh,
        });
        if (!data.success) {
          this.showToast("danger", "top-right", data.message, "XIcon");
        }
      } catch (error) {
        throw error;
      } finally {
      }
    },
    async initTimer() {
      if (this.minutesTime == 0 && this.secondsTime == 0) {
        await this.getReports();
        this.secondsTime = 59;
        this.minutesTime = 1;

        this.timerActive = false;
      } else {
        if (this.secondsTime == 0) {
          this.secondsTime = 59;
          if (this.minutesTime != 0) {
            this.minutesTime -= 1;
          }
        }
        this.secondsTime -= 1;

        setTimeout(() => {
          this.initTimer();
        }, 1000);
      }
    },
    async getUserPrograms() {
      try {
        let module = -1;
        if (this.program == 0) {
          module = 0;
        } else {
          const arrModules = [0, 3, 7, 6, 5, 8, 10, 11, 14];
          module = arrModules[parseInt(this.filters.program)];
        }
        this.advisor = null;

        this.filtersData.advisors = [];

        const response = await MaReportsService.getUserPrograms({
          idmodule: module,
          iduser: this.currentUser.user_id,
          idrole:
            this.isTeamLeader && this.isSuperAdmin
              ? 2
              : this.currentUser.role_id,
        });

        if (response.status == 200) {
          if (this.filters.advisor === this.currentUser.user_id) {
            const data = response.data.filter(
              (item) => item.id == this.currentUser.user_id
            );
            data.map((item) => {
              this.filtersData.advisors.push({
                label: item.user_name,
                value: item.id,
              });
            });
          } else {
            response.data.map((item) => {
              this.filtersData.advisors.push({
                label: item.user_name,
                value: item.id,
              });
            });
          }
        }
      } catch (error) {
        throw error;
      }
    },
    async getAllPrograms() {
      try {
        const response = await MaReportsService.getPrograms();

        if (response.status == 200) {
          let tempPrograms = response.data;
          tempPrograms = tempPrograms.filter((program) => program.id != 9);
          tempPrograms.map((item) => {
            this.filtersData.programs.push({
              label: item.value,
              value: item.id,
            });
          });
        }
      } catch (error) {
        throw error;
      }
    },
    async getReports() {
      
      try {
        this.timerActive = false;
        if (this.isCreated) this.addPreloader();
        let advisor;

        if (this.moduleId === 4) {
          advisor = null;
        } else {
          advisor = this.filters.advisor;
        }

        const response = await MaReportsService.getPaymentReport({
          year:  this.filters.year,
          program: this.filters.program,
          advisor: advisor,
          programid: this.programid,
          role_id:
            this.isTeamLeader && this.isSuperAdmin
              ? 2
              : this.currentUser.role_id,
        });

        if (response.status == 200) {
          this.reports = response.data;
          this.year = parseInt(this.filters.year);
          this.lastUpdate = this.reports[0].vl;
          if (this.isCreated) this.removePreloader();
          this.isProgress = false;
        }
      } catch (error) {
        if (this.isCreated) this.removePreloader();
        throw error;
      }
    },
    getYears() {
      for (let x = 2014; x <= moment().format("YYYY"); x++) {
        this.filtersData.years.push({ label: x, value: x });
      }
    },
    async activeSocketChangeProgress() {
      try {
        window.socket.subscribe("channel-chat");
        window.socket.bind(
          "payment-reports-progress-notification",
          async (response) => {
            const { counter, type, user_id } = response;
            this.isProgress = true;
            this.removePreloader();
            if (type == "payment-report") {
              this.value_progress = counter;
              if (
                this.value_progress >= 100 &&
                !(new Date() < this.dateDeploymentReport) &&
                this.currentUser.user_id == user_id
              ) {
                this.showToast(
                  "success",
                  "top-right",
                  "Payment report successfully generated!!",
                  "CheckIcon"
                );
              }
            }
            if (counter >= 100) {
              await this.getReports();
              this.isProgress = false;
            }
          }
        );
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    async destroySocket() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("payment-reports-progress-notification");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
    async getDateDeploymentReport() {
      try {
        const { data, status } =
          await MaReportsService.getDateDeploymentReport();

        if (status == 200) {
          this.dateDeploymentReport = new Date(data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style lang="scss">
//#ma-re-group-search.input-group:not(.bootstrap.touchspin):focus-within {
//  box-shadow: none !important;
//}
//
//#ma-re-select-year {
//  width: calc(100% - 50px);
//
//  .vs__dropdown-toggle {
//    border-radius: 4px 0px 0px 4px !important;
//  }
//}
//
//#ma-re-btn-search {
//  background: red;
//  width: 37px !important;
//  height: 37px !important;
//}

.letter {
  animation-name: loadingF;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-direction: linear;
  float: left;
  font-size: 14px;
  color: #777;
}

.l-1 {
  animation-delay: 0.48s;
}
.l-2 {
  animation-delay: 0.6s;
}
.l-3 {
  animation-delay: 0.72s;
}
.l-4 {
  animation-delay: 0.84s;
}
.l-5 {
  animation-delay: 0.96s;
}
.l-6 {
  animation-delay: 1.08s;
}
.l-7 {
  animation-delay: 1.2s;
}
.l-8 {
  animation-delay: 1.32s;
}
.l-9 {
  animation-delay: 1.44s;
}
.l-10 {
  animation-delay: 1.56s;
}
.l-11 {
  animation-delay: 1.68s;
}
.l-12 {
  animation-delay: 1.7s;
}
.l-13 {
  animation-delay: 1.82s;
}

@keyframes loadingF {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
