var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th'),_vm._l((_vm.monthsRefresh),function(item,index){return _c('b-th',{key:index},[_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(_vm._s(item.month))]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-form-checkbox',{attrs:{"disabled":item.disabled},model:{value:(item.refresh),callback:function ($$v) {_vm.$set(item, "refresh", $$v)},expression:"item.refresh"}})],1)])})],2)],1),_c('b-tbody',{staticStyle:{"font-size":"12px"}},[_vm._l((_vm.reports),function(item,index){return [(item.col1 == 'REMAINING')?_c('b-tr',{key:index,staticClass:"text-warning",attrs:{"meta":"FFFFC3"}},[_c('b-td',{staticClass:"font-size-09"},[_vm._v(" "+_vm._s(item.col1)+" ")]),_vm._l((12),function(cont,indexCont){return _c('b-td',{key:indexCont,style:(index == 0
                ? 'color:' + (_vm.isDarkTheme ? '#BABABA' : 'black')
                : index == 1
                ? ' font-weight: bold;'
                : index == 2
                ? 'font-weight: bold;'
                : index == 3
                ? 'font-weight: bold;'
                : index == 4
                ? 'font-weight: bold; '
                : index == (11 - (_vm.isNewRange?0:1))
                ? 'background:#008FE6; border: 1px solid white; color:white;  '
                : '')},[_c('span',{staticClass:"d-flex justify-content-center text-warning",style:(item.type_row == 11 ? 'font-weight: bolder ' : '')},[_vm._v(" "+_vm._s(item.type_row == 9 ? "$ " : "")+" "+_vm._s(item[_vm.monthValues[indexCont + 1]])+" ")])])})],2):_vm._e(),(item.col1 != 'REMAINING')?_c('b-tr',{key:index,style:(index == 1
              ? 'background:' +
                (_vm.isDarkTheme ? '#2169D280' : '#E8F1FF') +
                ';color:' +
                (_vm.isDarkTheme ? 'white' : '#2169D2')
              : index == 2
              ? 'background:' +
                (_vm.isDarkTheme ? '#E627D980' : '#FFE7FF') +
                ';color:' +
                (_vm.isDarkTheme ? '' : '#E627D9')
              : index == 3
              ? 'background :' +
                (_vm.isDarkTheme ? '#E91F3880' : '#FFE7EB') +
                ';color:' +
                (_vm.isDarkTheme ? '' : '#E91F38')
              : index == 4
              ? 'background:' +
                (_vm.isDarkTheme ? '#CD8C1480' : '#FFF7E8') +
                ';color:' +
                (_vm.isDarkTheme ? '' : '#CD8C14')
              : index == (11 - (_vm.isNewRange?0:1))
              ? 'background:' + (_vm.isDarkTheme ? '#393939' : '#C5C5C5')
              : ''),attrs:{"meta":"FFFFC3"}},[_c('b-td',{staticClass:"font-size-09",style:(index == 0
                ? 'color:' + (_vm.isDarkTheme ? '#BABABA' : 'black')
                : index == 1
                ? ' font-weight: bold;' +
                  'color:' +
                  (_vm.isDarkTheme ? '' : '#2169D2')
                : index == 2
                ? 'font-weight: bold;' +
                  'color:' +
                  (_vm.isDarkTheme ? '' : '#E627D9')
                : index == 3
                ? 'font-weight: bold;' +
                  'color:' +
                  (_vm.isDarkTheme ? '' : '#E91F38')
                : index == 4
                ? 'font-weight: bold;' +
                  'color:' +
                  (_vm.isDarkTheme ? '' : '#CD8C14')
                : index == (8 - (_vm.isNewRange?0:1))
                ? 'color:#008FE6;font-weight: bold;'
                : index == (9 - (_vm.isNewRange?0:1))
                ? 'color:red;font-weight: bold;'
                : index == (10 - (_vm.isNewRange?0:1))
                ? 'color:green;font-weight: bold;'
                : index == (11 - (_vm.isNewRange?0:1))
                ? 'background:#008FE6;font-weight: bold;color:white'
                : '')},[_vm._v(" "+_vm._s(item.col1)+" ")]),_vm._l((12),function(cont,indexCont){return _c('b-td',{key:indexCont,style:(index == 0
                ? 'color:' + (_vm.isDarkTheme ? '#BABABA' : 'black')
                : index == 1
                ? ' font-weight: bold;'
                : index == 2
                ? 'font-weight: bold;'
                : index == 3
                ? 'font-weight: bold;'
                : index == 4
                ? 'font-weight: bold; '
                : index == (8 - (_vm.isNewRange?0:1))
                ? 'color:#008FE6;font-weight: bold;'
                : index == (9 - (_vm.isNewRange?0:1))
                ? 'color:red;font-weight: bold;'
                : index == (10 -(_vm.isNewRange?0:1))
                ? 'color:green;font-weight: bold;'
                : index == (11 - (_vm.isNewRange?0:1))
                ? 'background:#008FE6; border: 1px solid white; color:white;  '
                : '')},[_c('span',{staticClass:"d-flex justify-content-center",style:(item.type_row == 11 ? 'font-weight: bolder ' : '')},[_vm._v(" "+_vm._s([81, 9].some(function (e) { return e == item.type_row; }) ? "$ " : "")+" "+_vm._s(item[_vm.monthValues[indexCont + 1]] == "-" ? "0" : item[_vm.monthValues[indexCont + 1]])+" ")])])})],2):_vm._e()]}),_c('b-tr',[_c('b-td'),_vm._l((12),function(item,index){return _c('b-td',{key:index},[_c('div',{staticClass:"d-flex justify-content-center"},[(
                _vm.reports[1][_vm.monthValues[index + 1]] != '-' &&
                _vm.reports[1][_vm.monthValues[index + 1]] != 0
              )?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.viewDetails(index + 1)}}},[_vm._v(" DETAILS ")]):_c('b-badge',{staticClass:"mt-1",attrs:{"variant":"danger"}},[_vm._v(" NO DETAILS ")])],1)])})],2)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }