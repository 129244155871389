<template>
  <b-sidebar
    :visible="isFiltersActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    right
    @hidden="close"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <span>
          <h3>Advanced Search</h3>
        </span>
        <span v-b-toggle.sidebar-right class="cursor-pointer" @click="close">
          <tabler-icon icon="XIcon" size="20" />
        </span>
      </div>
    </template>
    <b-container>
      <b-row>
        <b-col cols="12" class="mb-md-0 mb-2">
          <b-form-group label="Years" label-for="from">
            <v-select
              v-model="filters.year"
              :options="filtersData.years"
              :reduce="(el) => el.value"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-md-0 mb-2"
          v-if="
            moduleId !== 5 &&
            moduleId !== 7 &&
            moduleId !== 10 &&
            moduleId !== 14 &&
            moduleId !== 11
          "
        >
          <b-form-group label="Programs" label-for="from">
            <v-select
              v-model="filters.program"
              :options="filtersData.programs"
              :reduce="(el) => el.value"
              @change="changeProgram"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-md-0 mb-2"
          v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
        >
          <b-form-group label="Advisors" label-for="from">
            <v-select
              v-model="filters.advisor"
              :options="filtersData.advisors"
              :reduce="(el) => el.value"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <template #footer>
      <b-container>
        <b-row class="d-flex p-1 float-right">
          <b-button variant="info" @click="resetFiltersButtons">Reset</b-button>
          <b-button variant="primary" class="ml-1" @click="sideBarSearch">
            Search
          </b-button>
        </b-row>
      </b-container>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
export default {
  props: {
    isFiltersActive: {
      type: Boolean,
    },
    filters: {
      type: Object,
    },
    filtersData: {
      type: Object,
    },
  },
  components: {
    vSelect,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    resetFiltersButtons() {
      this.filters.year = "";
      this.filters.program = "";
      this.filters.advisor = "";
      this.$emit("reset-all-filters");
    },
    sideBarSearch() {
      this.$emit("onSearch");
      this.close(true);
    },
    changeProgram() {
      this.$emit("onChangeProgram");
    },
    close(save = false) {
      this.$emit("onClose", save);
    },
  },
  watch: {
    "filters.program"(newVal) {
      this.changeProgram();
    },
  },
  created() {},
};
</script>
